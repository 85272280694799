import React from "react";
import { useSelector } from "react-redux";
import Layout from "fragments/layout/layout";
import { Typography } from "@mui/material";
import { COLORS } from "constants/colors.js";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { selectUserStats } from "store/userSlice";

export default function Dashboard() {
  const usersStats = useSelector(selectUserStats);

  return (
    <Layout title="Dashboard">
      <div className="m-3">
        <Typography variant="h5">Dashboard</Typography>
        <div className="flex flex-col md:flex-row gap-4 mt-4">
          <div className="w-full bg-purple shadow-xl  flex flex-col gap-2 items-center justity-center py-6 md:py-14 rounded-2xl">
            <Typography variant="h4" sx={{ color: COLORS.white }}>
              ({usersStats?.verifiedUsers || 0})
            </Typography>
            <div className="flex gap-2 opacity-70">
              <PeopleAltIcon sx={{ color: COLORS.white }} />
              <Typography sx={{ color: COLORS.white, fontSize: "1.2rem" }}>
                Verified Users
              </Typography>
            </div>
          </div>

          <div className="w-full bg-orange shadow-xl flex flex-col gap-2 items-center justity-center py-6 md:py-14 rounded-2xl">
            <Typography variant="h4" sx={{ color: COLORS.white }}>
              ({usersStats?.unVerifiedUsers || 0})
            </Typography>
            <div className="flex gap-2 opacity-70">
              <PeopleAltIcon sx={{ color: COLORS.white }} />
              <Typography sx={{ color: COLORS.white, fontSize: "1.2rem" }}>
                Unverified Users
              </Typography>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
