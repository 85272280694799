import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersStats } from "apis";

export const fetchUserStats = createAsyncThunk(
  "users/fetchStats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUsersStats();
      return response.data.result;
    } catch (err) {
      return rejectWithValue(err.response?.data.message || "Failed to fetch stats");
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    verified: null,
    unverified: null,
    stats: {
      verifiedUsers: 0,
      unVerifiedUsers: 0,
      loading: false,
      initialized: false
    }
  },
  reducers: {
    setUsers(state, action) {
      if (action.payload.type === "verified") {
        state.verified = action.payload.users;
      } else if (action.payload.type === "unverified") {
        state.unverified = action.payload.users;
      }
    },
    deleteUser(state, action) {
      console.log(action.payload)
      if (action.payload.is_verified == 1) {
        state.verified = state.verified.filter(
          (item) => item.userId !== action.payload.userId
        );
      } else if (action.payload.is_verified == 0) {
        state.unverified = state.unverified.filter(
          (item) => item.userId !== action.payload.userId
        );
      }
    },
    updateUser(state, action) {
      if (action.payload.is_verified == 1) {
        const index = state.verified.findIndex(
          (item) => item.id === action.payload.id
        );
        state.verified[index] = { ...state.verified[index], ...action.payload };
      } else if (action.payload.is_verified == 0) {
        const index = state.unverified.findIndex(
          (item) => item.id === action.payload.id
        );
        state.unverified[index] = {
          ...state.unverified[index],
          ...action.payload,
        };
      }
    },
    verifyUser(state, action) {
      const index = state.unverified.findIndex(item => item.userId === action.payload.userId);
      if (index !== -1) {
        const verifiedUser = state.unverified.splice(index, 1)[0];
        state.verified.push(verifiedUser);
      }
    },
    addUser(state, action) {
      if (action.payload.is_verified == 1) {
        state.verified.push(action.payload);
      } else if (action.payload.is_verified == 0) {
        state.unverified.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStats.pending, (state) => {
        state.stats.loading = true;
      })
      .addCase(fetchUserStats.fulfilled, (state, action) => {
        state.stats = { 
          ...action.payload, 
          loading: false,
          initialized: true 
        };
      })
      .addCase(fetchUserStats.rejected, (state) => {
        state.stats.loading = false;
      });
  }
});

export const { setUsers, deleteUser, addUser, updateUser, verifyUser } = userSlice.actions;
export default userSlice.reducer;

// Update selector to use stats instead of array length
export const selectUserStats = (state) => state.users.stats;
