import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import subscriptionPackageReducer from "./subscriptionPackagesSlice";
import { userStatsMiddleware } from './userMiddleware';

const store = configureStore({
  reducer: {
    users: userReducer,
    subscriptionPackages: subscriptionPackageReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(userStatsMiddleware),
});

export default store;