import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Avatar, IconButton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Loader from "components/loader";
import { getVerifiedUsers } from "apis";
import { useToastsContext } from "contexts/toasts";
import { useModalsContext } from "contexts/modals";
import { convertDateTime } from "utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AdjustIcon from "@mui/icons-material/Adjust";
import { COLORS } from "constants/colors";
import ShowBool from "components/showBool";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setUsers, fetchUserStats } from "store/userSlice";

export default function VerifiedUsers() {
  const dispatch = useDispatch();
  const { verified } = useSelector((state) => state.users);
  const { toggleImageZoomModal, toggleUpdateUserModal, toggleDeleteUserModal } =
    useModalsContext();
  const { toggleToast } = useToastsContext();

  const [hasMore, setHasMore] = useState(true);

  const columnsForVerifiedUsers = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image) => {
          return (
            <Avatar
              onClick={(image) =>
                toggleImageZoomModal({
                  modalTitle: "User's Profile Image",
                  img: image.target.currentSrc,
                })
              }
              variant="rounded"
              src={image}
              sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
            />
          );
        },
      },
    },
    {
      name: "first_name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "User's Phone Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "Registered At",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dateTime) => {
          return <Typography>{convertDateTime(dateTime)}</Typography>;
          // return <Typography>{dateTime}</Typography>;
        },
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_banned",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool == 0 ? 1 : 0} type="status" />;
        },
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (userId) => {
          return (
            <div className="flex">
              <Link to={`/users/${userId}`}>
                <IconButton>
                  <VisibilityIcon sx={{ color: COLORS.primary }} />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => toggleDeleteUserModal(userId)}
                sx={{ color: COLORS.red }}>
                <DeleteOutlineIcon />
              </IconButton>
              <IconButton onClick={() => toggleUpdateUserModal(userId)}>
                <AdjustIcon sx={{ color: COLORS.primary }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const optionsForVerifiedUsers = {
    onRowClick: (user) => {
      // console.log(user);
      // const id = user[1];
      // navigate(`/users/${id}`);
    },
    onChangePage: (pageNumber) => {
      if ((pageNumber + 1) * 50 + 20 > verified.length && hasMore) {
        console.log("called");
        get_verified_users(pageNumber);
      } else {
        console.log("not call");
      }
    },
    selectableRowsHideCheckboxes: true,
    // selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [50],
    elevation: 2,
    filter: false,
    rowsPerPage: 50,
  };

  const get_verified_users = async (page) => {
    await getVerifiedUsers(page)
      .then((res) => {
        if (verified && verified.length > 0) {
          dispatch(
            setUsers({
              type: "verified",
              users: [...verified, ...res.data.result],
            })
          );
        } else {
          dispatch(setUsers({ type: "verified", users: res.data.result }));
        }
        // Stats will be fetched automatically by middleware
        if (res.data.result.length < 50) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        toggleToast({
          msg: err.response.data.message,
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (!verified?.length > 0) {
      get_verified_users(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-400 mt-5">
      {verified ? (
        <MUIDataTable
          title={"Verified Users"}
          data={verified}
          columns={columnsForVerifiedUsers}
          options={optionsForVerifiedUsers}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}
