import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Layout from "fragments/layout/layout";


// firebase
import {
  doc,
  collection,
  getDoc,
  query,
  onSnapshot,
  orderBy,
  addDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import {
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "components/loader";
import { useModalsContext } from "contexts/modals";

export default function ChaperoneChat() {
  const scrollToBottomRef = useRef(null);
  const messageEl = useRef(null);
  const params = useParams();
  const { roomId } = params;
  const { toggleImageZoomModal } = useModalsContext();

  const [msgs, setMsgs] = useState();
  const [room, setRoom] = useState();

  const getRoom = async (id) => {
    const docRef = doc(db, "chats", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setRoom(docSnap.data());
    } else {
      return;
    }
  };

  const readMsgs = async () => {
    const dbRef = collection(db, "chats", roomId, "messages");
    let arrOfData = [];

    const q = query(dbRef, orderBy("timestamp"));

    const unSub = onSnapshot(q, (snapchot) => {
      arrOfData = [];
      snapchot.docs.map((doc) => {
        arrOfData.push(doc.data());
      });
      setMsgs(arrOfData);
    });
  };

  const convertTime = (time) => {
    if (time) {
      const fireBaseTime = new Date(
        time.seconds * 1000 + time.nanoseconds / 1000000
      );
      const date = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toTimeString();

      return `${atTime.split(":")[0]}:${atTime.split(":")[1]}`;
    } else {
      return "sending...";
    }
  };

  // console.log(roomId);
  // console.log(msgs);
  // console.log(room);

  const scrollToBottom = () => {
    messageEl.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgs, roomId]);

  useEffect(() => {
    if (roomId) {
      getRoom(roomId);
      readMsgs();
    }
  }, [roomId]);
    
  scrollToBottom();

  const [msg, setMsg] = useState('');

  const sendMsg = async () => {
    const dbRef = collection(db, "chats", roomId, "messages");

    if (msg.length > 0) {
      await addDoc(dbRef, {
        to: 0,
        from: -1,
        message: msg,
        timestamp: serverTimestamp(),
        files: []
      })
        .then(async (res) => {
          const dbRef1 = doc(db, "chats", roomId);
          const docSnap = await getDoc(dbRef1);
          await updateDoc(dbRef1, {
            lastMessage: msg,
            timestamp: serverTimestamp(),
            unreadCount: docSnap.data().unreadCount + 1
          }).then(() => {
            setMsg('');
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    scrollToBottom();
  };


  return (
    <Layout title={`Chaperone Chat`}>
      {room ? (
        <div className="">
          <div className="flex justify-between items-center gap-4 px-4 py-2 border-b border-b-gray_light sticky top-12 z-100 bg-white border-bottom-2">
            <Link to={`/users/${room.user1.id}`}>
              <div className="flex gap-3">
                <Avatar
                  src={room.user1.image}
                  sx={{ width: "3.5rem", height: "3.5rem" }}
                />
                <div className="flex flex-col">
                  <Typography
                    variant="h6"
                    sx={{
                      maxWidth: "16rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    {room.user1.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      maxWidth: "16rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      opacity: "0.6",
                    }}>
                    {/* {room.user1.id} */}
                  </Typography>
                </div>
              </div>
            </Link>
            <Link to={`/users/${room.user2.id}`}>
              <div className="flex gap-3">
                <Avatar
                  src={room.user2.image}
                  sx={{ width: "3.5rem", height: "3.5rem" }}
                />
                <div className="flex flex-col">
                  <Typography
                    variant="h6"
                    sx={{
                      maxWidth: "16rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    {room.user2.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      maxWidth: "16rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      opacity: "0.6",
                    }}>
                    {/* {room.user2.id} */}
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
          {msgs ? (
            <>
              <div className="">
                {msgs.length > 0 ? (
                  <div className="">
                    {msgs.map((msg, i) => (
                      <div
                        className={
                          msg.from === room.user1.id || msg.from === room.user2.id ? "flex flex-col items-start" : "flex flex-col items-end"
                        }
                        key={i}>

                        <div
                          className={`${
                            msg.from === room.user1.id
                              ? "bg-yellow_light"
                              : msg.from === room.user2.id ? "bg-grey_light" 
                              : "bg-primary_light"
                          } mx-4 my-1 rounded-2xl p-4 max-w-[20rem] whitespace-wrap break-words`}>
                          <p>{msg.message}</p>
                          {msg.files.length > 0 && (
                            <>
                            {msg.files.map(file => (
                              <div className="cursor-pointer" onClick={() => toggleImageZoomModal({modalTitle: "Message Attachment",
                              img: file,})}>
                                <img src={file} alt="message attachment" className="max-w-[250px]" />
                              </div>
                            ))}
                            </>
                          )}
                          <p
                            style={{
                              fontSize: "12px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}>
                            <span>{msg.from === room.user1.id ? room.user1.name : msg.from === room.user2.id ? room.user2.name : "Chaperone"}</span>
                            <span>{convertTime(msg.timestamp)}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messageEl} className="p-12" />
                  </div>
                ) : (
                  <Typography variant="h5" sx={{ color: "red" }}>
                    No Messages yet
                  </Typography>
                )}
              </div>
              <div className="fixed bottom-0 left-0 right-0  bg-white p-4 shadow-md min-[900px]:left-[280px]">
                <div className="flex items-center w-full px-4">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message..."
                    size="small"
                    className="mr-2"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMsg();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <button
                          className="rounded-full p-2 hover:bg-grey_light"
                          aria-label="Send message"
                          onClick={sendMsg}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M14 5l7 7-7 7M5 12h14"
                            />
                          </svg>
                        </button>
                      ),
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}
