import React, { useState, useEffect } from "react";
import Layout from "fragments/layout/layout";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

// firebase
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";
import { Avatar, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Loader from "components/loader";
import { UpperCaseFirstLetter } from "utils";

export default function Chats() {
  const navigate = useNavigate();
  const params = useParams();
  const { supportType } = params;

  const [userType, setUserType] = useState();
  const [rooms, setRooms] = useState();

  const getRooms = async () => {
    const dbRef = collection(db, "supportChats");
    let arrOfData = [];

    const q = query(dbRef, where('users', "array-contains", userType ), orderBy("timestamp", "desc"));
    
    onSnapshot(q, (snapchot) => {
      arrOfData = [];
      snapchot.docs.map((doc) => {
        arrOfData.push({...doc.data(), id: doc.id});
      });
      setRooms(arrOfData);
    });
  };

  console.log(rooms)

  const convertTime = (time) => {
    const fireBaseTime = new Date(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );
    const date = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();

    // return `${date} at ${atTime.split(":")[0]}:${atTime.split(":")[1]} ${
    //   atTime.split("T")[1]
    // }`;

    return `${date} at ${atTime.split(":")[0]}:${atTime.split(":")[1]}`;
  };

  console.log(rooms);

  useEffect(() => {
    getRooms();
  }, [userType]);

  useEffect(() => {
    switch(supportType) {
      case "latin-support":
        setUserType(-1);
        break;
      case "revert-support":
        setUserType(-2);
        break;
      case "expert-support":
        setUserType(-3);
        break;
      default:
        navigate('/not-found');
    }
  }, [supportType])



  return (
    <Layout title="Chats">
      <div className="flex flex-col py-2 px-6">
        <Typography variant="h4" sx={{ margin: "1rem 0" }}>
          {UpperCaseFirstLetter(supportType)} Chats
        </Typography>
        {rooms ? (
          <>
            {rooms.length > 0 ? (
              <>
                {rooms.map((room, i) => (
                  <Link
                    to={`/${supportType}/chat/${room.user1.id}/${room.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                    key={i}>
                    <div className="flex items-center gap-2 my-2 p-2 bg-light hover:bg-hovered_bg rounded-2xl border border-gray_light">
                      <Avatar
                        src={room.user1?.image}
                        style={{ width: "4rem", height: "4rem" }}
                      />
                      <div className="flex flex-col whitespace-nowrap">
                        <h4>{room.user1?.name}</h4>
                        {!room.lastMessage ? (
                          <p className="flex flex-col whitespace-nowrap opacity-[0.5]">{room.lastMessage}</p>
                        ) : (
                          <div className="h-8 w-2"></div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          paddingRight: "1rem",
                          justifyContent: "flex-end",
                        }}>
                        <p style={{ opacity: "0.6", fontSize: "12px" }}>
                          {convertTime(room.timestamp)}
                        </p>
                        {room.new && (
                          <FiberManualRecordIcon
                            sx={{
                              width: "2rem",
                              height: "2rem",
                              color: "#8bdb7d",
                              marginLeft: "1rem",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <Typography
                variant="h5"
                sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                No data is available.
              </Typography>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  );
}
