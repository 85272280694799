import { fetchUserStats } from './userSlice';

const userActionsToWatch = [
  'users/deleteUser',
  'users/addUser',
  'users/verifyUser',
  'users/updateUser'
];

export const userStatsMiddleware = store => next => action => {
  const result = next(action);
  
  if (userActionsToWatch.includes(action.type) && 
      !store.getState().users.stats.loading && 
      store.getState().users.stats.initialized) {
    store.dispatch(fetchUserStats());
  }
  
  return result;
};
